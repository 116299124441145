<template>
  <div class="vip-wrapper">
    <p class="my-6 mx-3" style="font-size: 36px;">全站试题实时更新 | 历年真题 VIP 免费作答</p>
    <div class="flex flex-wrap">
      <div 
        class="md:w-1/3 w-full"
        :class="[index % 3 === 0 ? 'md:pb-3 md:px-0 px-3 pb-3':'md:pl-10 md:pr-0 md:pb-3 px-3 pb-3']"
        v-for="(item, index) of vipList"
        :key="item.id">
        <el-card class="text-center" shadow="hover">
          <p class="py-3 text-gray-700 font-bold text-xl">{{ item.name }}</p>
          <p class="text-md text-gray-500 line-through" style="height: 24px;">{{ item.fakePrice }}</p>
          <p class="pt-3 pb-4 text-red-400" style="font-size: 36px;">{{ item.price }}</p>
          <el-button class="my-2" plain @click="buyVip">立即开通</el-button>
          <el-divider></el-divider>
          <div class="flex justify-center">
            <ul v-if="item.description.length > 0" class="text-gray-500">
              <li class="pb-1 text-left" v-for="(desc, descIndex) of item.description" :key="descIndex"><i class="el-icon-success text-green-500"></i> {{ desc }}</li>
            </ul>
          </div>
        </el-card>
        
      </div>
    </div>
    <p class="my-6" style="font-size: 36px;">属于我们自己的在线考试辅导平台</p>
    <el-divider></el-divider>
    <div class="flex flex-wrap">
      <div class="md:w-1/4 w-1/2 p-4">
        <span class="text-2xl">历年真题</span>
        <p class="mt-3 text-gray-500">等级考试、NOIP等历年试卷真题，打造VIP专属题库</p>
      </div>
      <div class="md:w-1/4 w-1/2 p-4">
        <span class="text-2xl">模拟测试</span>
        <p class="mt-3 text-gray-500">覆盖分类包含 Scratch、C++、Python 等多个领域，更贴近实际学习所需</p>
      </div>
      <div class="md:w-1/4 w-1/2 p-4">
        <span class="text-2xl">不止提升</span>
        <p class="mt-3 text-gray-500">累计合作百位专业教师师，严控试题普及、考点、难易、严谨</p>
      </div>
      <div class="md:w-1/4 w-1/2 p-4">
        <span class="text-2xl">引领行业</span>
        <p class="mt-3 text-gray-500">甄选成套试题，历年真题、模拟试题、专属练习，知识绝不错过</p>
      </div>
    </div>
    <el-divider></el-divider>
    <div class="bg-yellow-500 text-white font-medium text-xl inline-block py-4 px-6 mb-6 rounded-full">开通VIP，在线做练习</div>
  </div>
</template>

<script>
export default {
  name: 'VIPCenter',
  data() {
    return {
      vipList: [
        { id: 1, name: '三次卡 VIP', price: '29 元/3次', fakePrice: '30 元/3次', description: ['每日更新编程题库', '学习交流群答疑', '生成试卷：3 次'] },
        { id: 2, name: '十次卡 VIP', price: '59 元/10次', fakePrice: '60 元/10次', description: ['每日更新编程题库', '学习交流群答疑', '生成试卷：10 次'] },
        { id: 3, name: '终身 VIP', price: '599 元/无限次', fakePrice: '899 元/无限次', description: ['每日更新编程题库', '学习交流群答疑', '生成试卷：无限制'] },
      ]
    }
  },
  methods: {
    buyVip() {
      this.$message({
        type: 'warning',
        message: '即将开放，敬请期待'
      })
    }
  }
}
</script>